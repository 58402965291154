import React from "react";
import { graphql } from "gatsby";
import { Layout, Row, Col, Collapse } from "antd";
import { Helmet } from "react-helmet";
import Header from "../../components/common/header";
import CommonFooter from "../../components/common/footer";
import AboutSider from "../../components/about/about-sider";

import css from "../../styles/guide.css";

const Panel = Collapse.Panel;

export default ({ data }) => {
  console.log(data);

  return (
    <div>
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>DayViewer Planner - Privacy</title>
          <meta
            name="description"
            content="Privacy policy of the DayViewer online calendar planner and team collaboration system."
          />
        </Helmet>
        <Header />
        <Layout style={{ minHeight: "100vh" }}>
          <AboutSider />

          <Layout className="guide-body">
            <Row>
              <Col>
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.iubendaDocument.privacyPolicy,
                  }}
                />
              </Col>
            </Row>
          </Layout>
        </Layout>
        <CommonFooter />
      </Layout>
    </div>
  );
};

export const query = graphql`
  query {
    iubendaDocument {
      privacyPolicy
    }
  }
`;
